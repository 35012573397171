<template>
  <Loader :value="promises.fetch" theme="overlay" class="project-page">
    <Blocks :blocks="config" :customComponents="customComponents"></Blocks>
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import BrowserApiMixin from '@/mixins/browser-api'
import { Blocks } from '@kvass/pagebuilder'

import ContentOnImage from '@/components/ContentOnImage.vue'

import Config from '@/config/project.js'

import { animate } from 'motion'

export default {
  mixins: [BrowserApiMixin('project')],
  computed: {
    ...mapState('Project', ['promises', 'item']),

    config() {
      return Config.call(this)
    },
    customComponents() {
      return {
        ContentOnImage,
      }
    },
  },
  methods: {
    ...mapActions('Project', ['fetch']),
    async initAnimation() {
      animate('.project-logo', { opacity: 1, transform: 'none' }, { duration: 0.4, delay: 0 })

      if (!document.querySelector('.content-on-image__wrapper')) return

      animate(
        '.content-on-image__wrapper ',
        { opacity: 1, transform: 'none' },
        { duration: 0.4, delay: 0 },
      ),
        animate(
          '.content-on-image__content',
          { opacity: 1, transform: 'none' },
          { duration: 0.4, delay: 0.2 },
        )

      const button = document.querySelectorAll('a[data-cta]')
      if (button.length) {
        animate(button, { opacity: 1, transform: 'none' }, { duration: 2, delay: 0.6 })
      }
    },
  },
  created() {
    this.fetch().then(() => this.$nextTick(() => this.initAnimation()))
  },
  metaInfo() {
    return {
      title: this.$t('home'),
    }
  },
  components: {
    Blocks,
  },
}
</script>

<style lang="scss">
.project-page {
  background-color: var(--primary);

  .project-logo {
    opacity: 0;
    width: 25%;

    @include respond-below('phone') {
      width: 100%;
    }

    margin: 0 auto;
    .kpb-image__image {
      background-color: inherit;
      --kpb-image-object-fit: contain;
    }
  }

  .post-card {
    --section-padding-block: 3rem;

    @include respond-below('phone') {
      --section-padding-block: 1rem;
    }

    &__layout {
      gap: 1vh !important;
      @include respond-below('phone') {
        flex-direction: column !important;
        gap: 2vh !important;
      }
    }
    &__card {
      width: 50%;

      @include respond-below('phone') {
        width: 100%;
      }
    }
  }
}
</style>
