<template>
  <div class="some-footer__social-icons">
    <template v-for="entry in soMeEntries">
      <a :key="entry.type" v-if="entry.link" :href="entry.link" target="_blank">
        <FontAwesomeIcon :icon="entry.icon" size="1x" />
        <span class="some-footer__social-text">{{ entry.type }}</span>
      </a>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Array, Object],
    },
  },
  computed: {
    soMeEntries() {
      return [
        {
          type: 'Linkedin',
          icon: ['fab', 'linkedin'],
          link: this.value.linkedin_url,
        },
        {
          type: 'Facebook',
          icon: ['fab', 'facebook'],
          link: this.value.facebook_url,
        },
        {
          type: 'Instagram',
          icon: ['fab', 'instagram'],
          link: this.value.instagram_url,
        },
        {
          type: 'Twitter',
          icon: ['fab', 'twitter'],
          link: this.value.twitter_url,
        },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
.some-footer {
  &__social-icons {
    display: flex;
    gap: 1rem;

    @include respond-below('phone') {
      flex-direction: column;
      gap: 0.8rem;
    }
    .fa-1x {
      font-size: 1.8em;
    }
    a {
      margin-right: 0.8rem;
      color: inherit;
      display: inline-flex;
      gap: 1rem;
      align-items: center;
      justify-content: center;

      @include respond-below('phone') {
        gap: 1rem;
      }
    }
  }
  &__social-text {
    @include respond-above('phone') {
      display: none;
    }
  }
}
</style>
