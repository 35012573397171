import { TransformTag } from '@/utils'

export default function() {
  const get = path => this.$path(path, this.$store.state)

  return [
    {
      component: 'Section',
      props: {
        width: 'full',
        style: '--section-padding-block: calc(9vh + 15px) 8vh',
      },
      blocks: {
        default: {
          component: 'Image',
          props: {
            class: 'project-logo',
          },
          data: {
            image: {
              url: 'https://assets.kvass.no/63ce4244a9d58f1402401c44.gif',
            },
          },
        },
      },
    },

    {
      component: 'Section',
      props: {
        width: 'full',
        class: 'post-card',
        style: '',
      },
      blocks: {
        default: {
          component: 'Flex',
          props: {
            class: 'post-card__layout',
          },
          blocks: {
            default: [
              {
                component: 'ContentOnImage',
                props: {
                  class: 'post-card__card post-card__card--about',
                },
                data: {
                  image: get('Project.item.customFields.about.image'),
                  icon: get('Project.item.customFields.about.icon'),
                  content: get('Project.item.customFields.about.content'),
                },
              },
              {
                component: 'ContentOnImage',
                props: {
                  class: 'post-card__card post-card__card--rent',
                },
                data: {
                  image: get('Project.item.customFields.rental.image'),
                  icon: get('Project.item.customFields.rental.icon'),
                  content: get('Project.item.customFields.rental.content'),
                },
              },
            ],
          },
        },
      },
    },
  ]
}
