<template>
  <div class="content-on-image">
    <img :src="data.image ? data.image.url : undefined" class="content-on-image__image" />
    <div class="content-on-image__wrapper">
      <img
        v-if="data.icon"
        :src="data.icon ? data.icon.url : undefined"
        class="content-on-image__icon"
      />
      <div v-html="data.content" class="content-on-image__content"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        content: '',
        image: {},
      }),
    },
  },
  components: {},
}
</script>

<style lang="scss">
.content-on-image {
  position: relative;
  display: flex;
  color: white;

  &__image {
    background: no-repeat 50% 50%;
    object-fit: cover;
    width: 100%;
    min-height: calc(1335px * 0.6);

    @include respond-below('phone') {
      min-height: calc(844px * 0.6);
    }
  }

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(60%, rgba(0, 0, 0, 0.2)),
      to(transparent)
    );
    background: -o-linear-gradient(rgba(0, 0, 0, 0.2) 60%, transparent);
    background: linear-gradient(rgba(0, 0, 0, 0.2) 60%, transparent);
    pointer-events: none;
  }

  &__wrapper {
    opacity: 0;

    padding: 2rem 1.5rem;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    justify-content: center;
  }

  &__icon {
    margin-bottom: -1%;
    margin-right: auto;
    object-fit: contain;
    max-height: 35px;
  }

  &__content {
    opacity: 0;
    h1,
    h2 {
    }

    width: 100%;
  }
}
</style>
