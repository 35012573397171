import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import API from '@/api'

import Loading from '@/views/Loading.vue'
import Project from '@/views/Project.vue'
import { ScrollToAnchor } from '@/utils'
Vue.use(VueMeta)
Vue.use(VueRouter)

function getRoutes() {
  return [
    {
      path: '/',
      name: 'Project',
      component: Project,
      meta: {
        navigation: false,
      },
    },
    {
      path: '/residential/:id',
      name: 'Residential',
      props: true,
      component: () =>
        LazyLoadView(import(/* webpackChunkName: "residential" */ '@/views/Residential.vue')),
    },
    {
      path: '/embed/flatfinder',
      name: 'Flatfinder',
      props: true,
      meta: {
        navigation: false,
        footer: false,
      },
      component: () =>
        LazyLoadView(import(/* webpackChunkName: "flatfinder" */ '@/views/Embed/Flatfinder.vue')),
    },
    {
      path: '/embed/residentials',
      name: 'Residentials',
      props: true,
      meta: {
        navigation: false,
        footer: false,
      },
      component: () =>
        LazyLoadView(
          import(/* webpackChunkName: "residentials" */ '@/views/Embed/Residentials.vue'),
        ),
    },

    {
      path: '/resources/juridisk/personvern-forbehold',
      name: 'Policy',

      component: () =>
        LazyLoadView(import(/* webpackChunkName: "policy" */ '@/views/Policy/Component.vue')),
    },

    {
      path: '/resources/juridisk/personvern-forbehold/cookie-erklaering',
      name: 'Cookie',
      component: () =>
        LazyLoadView(import(/* webpackChunkName: "cookie" */ '@/views/Policy/Cookie.vue')),
    },

    {
      path: '/resources/juridisk/personvern-forbehold/personvernerklaering',
      name: 'Personvernerklaering',
      component: () =>
        LazyLoadView(import(/* webpackChunkName: "privacy" */ '@/views/Policy/Privacy.vue')),
    },

    {
      path: '/resources/juridisk/personvern-forbehold/forbehold',
      name: 'Forbehold',
      component: () =>
        LazyLoadView(import(/* webpackChunkName: "forbehold" */ '@/views/Policy/Forbehold.vue')),
    },

    {
      path: '/:slug',
      name: 'Post',
      props: true,
      component: () =>
        LazyLoadView(import(/* webpackChunkName: "post" */ '@/views/Post/Component.vue')),
    },
    {
      path: '/:slug/:main',
      name: 'Main',
      props: true,
      component: () =>
        LazyLoadView(import(/* webpackChunkName: "main */ '@/views/Main/Component.vue')),
    },
    {
      path: '/:slug/:main/:subpage',
      name: 'Subpage',
      props: true,
      component: () =>
        LazyLoadView(import(/* webpackChunkName: "subpage */ '@/views/Subpage/Component.vue')),
    },
    {
      path: '*',
      redirect: {
        name: 'Project',
      },
    },
  ]
}

const routes = getRoutes()

function LazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    loading: Loading,
    delay: 150,
    // error: require('@views/_timeout.vue').default,
    // timeout: 10000,
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      return h(AsyncHandler, data, children)
    },
  })
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return ScrollToAnchor(to.hash.substring(1))
    }
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next) => {
  window.sessionStorage.removeItem('flatfinder_current')

  if (!hasQueryParams(to) && hasQueryParams(from)) {
    next(Object.assign({}, to, { query: from.query }))
  } else {
    next()
  }
})

export default router
