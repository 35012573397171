<template>
  <footer class="footer">
    <div class="footer__container container">
      <router-link :to="mainAction" @click.native="scrollToTop">
        <img class="footer__logo" :src="logo" :alt="item.name" />
      </router-link>
      <div class="footer__menu">
        <template v-for="(item, index) in navigation">
          <ActionComponent
            v-if="$path('action.name', item) !== $path('name', $route)"
            class="footer__menu-action"
            :class="{ 'footer__menu-action--empty': !item.action }"
            v-bind="item"
            :key="index"
          />
          <template v-if="item.items && item.items.length">
            <ActionComponent
              v-for="(nested, ni) in item.items"
              class="footer__menu-action footer__menu-action--indent"
              v-bind="nested"
              :key="index + '_' + ni"
            />
          </template>
        </template>
      </div>

      <div class="footer__some">
        <span>Følg oss på sosiale medier:</span>

        <SoMeFooter v-if="someCustomField.length !== 0" :value="someCustomField" />
      </div>
      <div class="footer__policy footer__links">
        <router-link to="/resources/juridisk/personvern-forbehold"
          >Personvern og forbehold</router-link
        >

        <a href="#" @click.prevent="editConsents">{{ $t('editConsents') }}</a>

        <div class="footer__copyright">Copyright &copy; {{ year }}</div>
      </div>

      <div class="footer__bottom">
        <span>
          {{ $t('pageMadeBy') }}
          <a :href="`https://www.kvass.no?ref=${currentUrl}`" target="_blank">Kvass</a>
        </span>
        <br />
        <span>
          Webdesign og profil av
          <a href="https://www.pulsecom.no/">Pulse Communications</a>
        </span>
        <br />
        <span>
          Illustrasjoner er utarbeidet av
          <a href="https://dark.no/">Dark Design Group</a>
          /
          <a href="https://www.grape.no/"> Grape </a>
          /
          <a href="https://www.tegmark.net/"> Tegmark </a>
          /
          <a href="https://en.3destate.no/"> 3D Estate </a>
        </span>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex'
import API from '@/api'
import { GetCustomField } from '@/utils'

import ActionComponent from 'vue-elder-navigation/src/action'
import SoMeFooter from '@/components/SoMeFooter.vue'

export default {
  props: {
    navigation: Array,

    cookieManager: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      year: new Date().getFullYear(),
    }
  },
  computed: {
    ...mapState('Root', ['item']),
    someCustomField() {
      if (!this.item) return
      return GetCustomField('social-media', this.item.customFields) || []
    },

    currentUrl() {
      return window.location.origin
    },

    residentialId() {
      return this.$route.params.id || null
    },

    slug() {
      return this.$route.params.slug || null
    },
    mainSlug() {
      return this.$route.params.main || null
    },

    mainAction() {
      if (!this.slug && !this.residentialId) return '/'

      if (this.residentialId) return '/utleie'

      return '/' + this.slug
    },

    logo() {
      //return logo based on primary-color lightness

      if (!this.item.customFields) return

      let backgroundLightness = getComputedStyle(document.documentElement).getPropertyValue(
        '--primary-l',
      )
      if (this.item.customFields['footer-logo']) return this.item.customFields['footer-logo'].url
      if (parseFloat(backgroundLightness) < 70) return API.logoInverted

      return API.logo
    },
  },
  methods: {
    editConsents() {
      this.cookieManager.show()
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
  },

  components: {
    ActionComponent,
    SoMeFooter,
  },
}
</script>

<style lang="scss">
.footer {
  background-color: var(--primary);
  color: $beige;
  padding-top: 4rem;

  &__some {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    @include respond-below('phone') {
      gap: 1.5rem;
    }
  }

  &__logo {
    max-width: 160px;
    width: 100%;
    height: 100%;
    max-height: 60px;
  }

  &__bottom {
    padding-block: 15px;

    text-align: center;

    a {
      &:hover {
        text-decoration: underline;
      }
    }

    & > div {
      display: flex;
      justify-content: space-between;

      gap: 1rem;
      flex-wrap: wrap;
    }
    &-text {
      text-align: justify;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
  }

  &__copyright {
    a {
      font-weight: bold;
    }
  }

  &__policy {
    font-size: 0.875rem;

    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__menu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    &-action {
      cursor: pointer;
      text-transform: uppercase;
      font-size: 0.875rem;
      padding: 0 0.5rem 0.5rem;

      background-color: transparent !important;
      color: inherit;
      font-weight: inherit;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  p {
    margin-top: 0;
  }

  &__links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @include respond-below('phone') {
      align-items: center;
      justify-content: center;
    }

    gap: 1rem;
  }
}
</style>
